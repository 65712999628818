.form-card {

  &-floating {
    input {
      &:not(:focus) {
        &:not(:placeholder-shown) {
          &:invalid {
            + {
              .form-control-placeholder {
                font-size: 65%;
                transform: translate3d(0, -100%, 0);
                opacity: 1;
                top: 17px;
                color: $primary-red;
              }
            }

            ~ {
              .invalid-feedback {
                display: block;
              }
            }
          }

          &:valid {
            + {
              .form-control-placeholder {
                font-size: 65%;
                transform: translate3d(0, -100%, 0);
                top: 17px;
                opacity: 1;
              }
            }
          }
        }
      }

      position: relative;
      background: transparent;
      z-index: 2;
    }

    .form-control {
      &:placeholder-shown {
        + {
          .form-control-placeholder {
            top: 8px;
            transform: translate3d(0, 0, 0);
            font-size: 100%;
          }
        }
      }

      &:not(placeholder-shown) {
        + {
          .form-control-placeholder {
            font-size: 65%;
            top: 17px;
            transform: translate3d(0, -100%, 0);
            opacity: 1;
          }
        }
      }

      &:focus {
        + {
          .form-control-placeholder {
            font-size: 65%;
            transform: translate3d(0, -100%, 0);
            top: 17px;
            opacity: 1;
          }
        }
      }
    }

    textarea#message {
      position: relative;
      background: transparent;
      z-index: 2;
      padding: 20px 16px 12px;
    }

    .form-group {
      position: relative;
      margin-bottom: 1.5rem;
    }

    .form-control-placeholder {
      position: absolute;
      z-index: 1;
      top: 8px;
      padding: 0;
      transition: all 300ms;
      color: $primary-black;
      left: 10px;
    }
  }

  select {
    width: 100%;
  }

  .input-field-container {
    background: $primary-white;
    margin-bottom: 13px;
    border-radius: 5px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }

    &__text {
      max-height: 223px;
    }
  }

  .form-check {
    position: relative;

    @include media-breakpoint-down($hamburger-max-breakpoint) {
      margin-bottom: 20px;
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border: 1px solid $primary-gold;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-color: $primary-white;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        top: calc(50% - 12px);
      }
    }

    &-label {
      font-style: normal;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      padding: 2px 0 0 8px;
      margin-left: 30px;

      a {
        color: $primary-grey;
        text-decoration: underline;
      }
    }
  }

  .form-control {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 400;
    color: $primary-grey;
    margin: 0;
    width: 100%;
    height: 40px;
    border: 1px solid #F0D9B0;
    border-radius: 5px;

    &--select {
      font-family: var(--bs-body-font-family);
      font-size: 100%;
      padding: 9px 16px;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  textarea {
    height: auto !important;
    resize: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .form-radio-input {
    top: 3px;
  }

  .form-radio-group .input-field-container {
    margin-bottom: 10px;
  }
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  padding: 3px 16px;
  font-size: 80%;
  color: $primary-white;
  background: $primary-red;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 2;
}

.input-field-container__text {
  .invalid-feedback {
    top: -8px;
  }
}

.was-validated {
  :invalid {
    ~ {
      .invalid-feedback {
        display: block;
      }
    }
  }

  .form-check-input {
    &:invalid {
      ~ {
        .form-check-label {
          color: $primary-red;
        }
      }
    }
  }

  .form-control {
    &:valid {
      border-color: #28a745;
    }
  }
}

.success {
  text-align: center;
  display: none;

  &__icon {
    background: url(../../img/components/form/check.svg) bottom center no-repeat;
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto 0;
    transform: scale(0.8);
    @include media-breakpoint-down(lg) {
      transform: scale(0.67);
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 16px;
    line-height: 24px;
    color: $primary-grey;
    margin-bottom: 0;
  }

  &__btn {
    &__invers {
      text-transform: uppercase;
    }
  }

  &.show {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
  }
}