[data-tab-content] {
  display: none;
}

.active[data-tab-content] {
  display: block;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  margin: 36px 0;
  padding: 0;
  gap: 78px;

  @include media-breakpoint-down($hamburger-max-breakpoint) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.tab {
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
  color: $primary-black;
}

.tab-li {
  flex: 1;
}

.tab-li a {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 56px;
  background: linear-gradient(270deg, transparent 0%, transparent 100%);
  color: $primary-black;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: 1px solid $primary-grey;

  @include media-breakpoint-down($hamburger-max-breakpoint) {
    max-width: 410px;
  }
}

.tab-li a.active, .tab-li a:hover {
  background: linear-gradient(270deg, $primary-grey 0%, $primary-black 100%);
  color: $secondary-grey;
  border: 1px solid transparent;
}

.tab-li a {
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
