@import "@fontsource/montserrat/latin.css";

// Our global style variables
@import "variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Custom styles
@import "mixins";
@import "components/form";
@import "components/header";
@import "components/footer";
@import "components/tab";
@import "components/breadcrumb";
@import "components/splide";
@import "style";

// Splide
@import 'splide';