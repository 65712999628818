.breadcrumbs {
  padding: 0 40px;

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }

  &__nav {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.42px;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }

    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0 0 20px;
      padding: 0;

      li {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;

        a {
          color: $secondary-grey;
        }

        &:first-child {
          @media (max-width: 425px) {
            a {
              display: none;
            }
          }
        }
      }
    }
  }

  &__home {
    margin-right: 3px;
  }

  &__separtator {

  }
}
