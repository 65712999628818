#top-nav-contact {
  padding: 12px 0 0;
  gap: 11px;

  @include media-breakpoint-down($hamburger-max-breakpoint) {
    display: none;
  }

  .navbar-nav {
    flex: 1;
    justify-content: flex-end;
    gap: 66px;

    li {
      span {
        margin-right: 8px;
      }
    }

    a {
      color: $secondary-grey;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.48px;
      text-decoration: none;
    }
  }
}

#top-nav {
  padding: 0;
}

#top {
  position: relative;
  background: linear-gradient(90deg, #32373F 0%, #201C1D 100%);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);

  .navbar-brand {
    padding: 11px 0;
    @include media-breakpoint-up($hamburger-max-breakpoint) {
      padding: 0;
    }
  }

  .logo {
    display: flex;

    @include media-breakpoint-up($hamburger-max-breakpoint) {
      top: -18px;
      position: relative;
    }

    .img {
      background: url(../../img/components/logo-xs.png);
      width: 152px;
      height: 54.71px;
      @include media-breakpoint-up($hamburger-max-breakpoint) {
        background: url(../../img/components/logo-xl.png);
        width: 125px;
        height: 45px;
      }
      background-position: center !important;
    }
  }

  .langswitcher {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down($hamburger-max-breakpoint) {
      padding: 0 24px;
    }

    &__link {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.42px;
      text-decoration: none;
      color: $primary-white;

      &.link {
        &--current {
          font-weight: 600;
        }
      }
    }

    li {
      position: relative;

      &:after {
        content: 'I';
        position: absolute;
        color: #fff;
        right: -12px;
        top: 0.5px;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .offcanvas {
    @include media-breakpoint-down($hamburger-max-breakpoint) {
      max-width: 256px;
      top: 77px;
    }

    @include media-breakpoint-up($hamburger-max-breakpoint) {
      align-items: flex-end;
    }

    &-backdrop.show {
      opacity: 0;
    }

    .navbar-nav {
      gap: 31px;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        gap: 0;
      }
    }

    .nav-link {
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.42px;
      text-transform: uppercase;
      padding-top: 13px;
      padding-bottom: 12px;
      position: relative;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        padding: 12px 24px 13px 24px;
      }

      &:hover::before, &.active::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none"><path d="M3.94971 0L7.81149 4.94985 3.94971 9.89949 0.0879201 4.94964 3.94971 0Z" fill="url(%23paint0_linear_1264_200)"/><defs><linearGradient id="paint0_linear_1264_200" x1="6.4023" y1="7.4469" x2="1.47007" y2="2.51032" gradientUnits="userSpaceOnUse"><stop stop-color="%23D6B184"/><stop offset="0.479167" stop-color="%23FFECC9"/><stop offset="1" stop-color="%23D6B184"/></linearGradient></defs></svg>');
        position: absolute;
        left: -12px;

        @include media-breakpoint-down($hamburger-max-breakpoint) {
          left: 10px;
        }
      }

      &:hover::after, &.active::after {
        display: block;
        position: absolute;
        bottom: 0;
        content: '';
        background: linear-gradient(270deg, #D6B184 0.02%, $third-gold 47.77%, #D6B184 99.67%);
        height: 1px;
        width: 100%;
        margin: 0;
        vertical-align: unset;
        border: none;

        @include media-breakpoint-down($hamburger-max-breakpoint) {
          max-width: 100px;
        }
      }
    }

    .langswitcher {
      @include media-breakpoint-up($hamburger-max-breakpoint) {
        display: none;
      }
    }
  }

  .dropdown-menu {
    max-width: 160px;
    @include media-breakpoint-down($hamburger-max-breakpoint) {
      max-width: 100%;
    }


    li {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0.51px;
        width: 70px;
        height: 1px;
        margin-left: 15px;
        background: url('data:image/svg+xml,<svg width= "70" height= "1" viewBox= "0 0 70 1" fill= "none" xmlns= "http://www.w3.org/2000/svg" ><line x1= "2.22928e-08" y1= "0.745" x2= "70" y2= "0.745006" stroke= "%23F0D9B0" stroke-width= "0.51" /></svg>') no-repeat;

        @include media-breakpoint-down($hamburger-max-breakpoint) {
          margin-left: 36px;
        }
      }

      &:last-child {
        &:after {
          background: none;
        }
      }
    }

    .dropdown-item {
      white-space: normal;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        padding-left: 36px;
      }
    }
  }
}

