// This file contains the variables used in 3rd party Bootstrap plugins and
// bootstrap customisations.

$body-bg: $primary-white;
$body-color: $primary-grey;

$nav-link-font-weight: 300;
$navbar-nav-link-padding-x: 0;
$navbar-light-color: $secondary-grey;
$navbar-light-hover-color: $secondary-grey;
$navbar-light-active-color: $secondary-grey;
$navbar-light-disabled-color: $secondary-grey;

$offcanvas-bg-color: $primary-grey;

$dropdown-min-width: 160px;
$dropdown-max-width: 160px;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-font-size: 14px;
$dropdown-bg: $primary-grey;
$dropdown-border-color: $primary-grey;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-divider-bg: $primary-gold;
$dropdown-divider-margin-y: 0;
$dropdown-link-color: $primary-gold;
$dropdown-link-hover-color: $primary-gold;
$dropdown-link-hover-bg: $primary-black;
$dropdown-link-active-color: $primary-gold;
$dropdown-link-active-bg: $primary-black;
$dropdown-link-disabled-color: $secondary-grey;
$dropdown-item-padding-y: 0.6rem;
$dropdown-item-padding-x: 1rem;
$dropdown-header-color: $secondary-grey;
//$caret-width: 0.4em;
//$caret-spacing: 2.2em;
//$caret-vertical-align: 0.8em;

$modal-backdrop-bg: $primary-grey;
$modal-backdrop-opacity: .9;
$modal-content-bg: transparent;
$modal-content-border-color: transparent;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;

$btn-disabled-color: $quinary-grey;

$grid-gutter-widths: (
        xs: 16px,
        sm: 20px,
        md: 24px,
        lg: 32px,
        xl: 32px
);

$grid-container-margins: (
        xs: 24px,
        sm: 10px,
        md: 12px,
        lg: 16px,
        xl: 16px
);

$hamburger-max-breakpoint: 'lg';

$font-family-sans-serif: Montserrat, system-ui, -apple-system
