// Our theme's custom variables

// Colors:

// Inverz szövegszín, háttérszín
$primary-white: #ffffff;

// Szövegszín, háttérszín
$primary-black: #201C1D;

// Link szövegszín, háttérszín
$primary-gold: #F0D9B0;

$secondary-gold: #D6B184;
$third-gold: #FFECC9;

// Elsődleges szövegszín
$primary-grey: #32373F;

// Másodlagos szövegszín
$secondary-grey: #F2F2F2;

$tertiary-grey: #AEB8BE;
$quaternary-grey: #C0CBD1;
$quinary-grey: #EFF1F1;
$octonary-grey: #E0E4E6;

// Alert szín
$primary-red: #DF0000E5;

// Másodlagos alert szín
$secondary-red: #EE534F;

$base-font-family: Montserrat, sans-serif;
$headings-font-family: Argesta, sans-serif;
