footer {
  background: $primary-grey;
  color: $primary-white;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: $primary-white;
    transition: all 0.5s ease;

    &:hover {
      color: $primary-gold;
      transition: all 0.5s ease;
    }
  }

  .footer-container {

    &--form {
      background: linear-gradient(270deg, $secondary-gold 0.02%, #F0D9B0 47.77%, $secondary-gold 99.67%);
      padding: 27px 0 44px;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        padding: 24px 0 20px;
      }

      .from-title-container {
        padding-top: 27px;
        padding-bottom: 36px;

        @include media-breakpoint-down(md) {
          padding-bottom: 65px;
        }

        h2 {
          color: $primary-grey;
          font-size: 38px;
          font-weight: 400;
          letter-spacing: 1.14px;
          text-transform: uppercase;
          margin: 0;
          position: relative;

          @include media-breakpoint-down(lg) {
            font-size: 28px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.84px;
          }

          @include media-breakpoint-down(md) {
            text-align: center;
          }

          span {
            position: relative;

            &::after {
              content: "";
              background: url('data:image/svg+xml,<svg width="86" height="14" viewBox="0 0 86 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.17295 6.99996L7.00001 0.812837L11.8284 7.0013L7.00131 13.1884L2.17295 6.99996Z" fill="%2332373F" stroke="%2332373F"/><line x1="29.9995" y1="6.49951" x2="85.9995" y2="6.49951" stroke="%2332373F"/></svg>') no-repeat;
              width: 86px;
              height: 14px;
              position: absolute;
              right: -108px;
              top: 14px;

              @include media-breakpoint-down(lg) {
                top: 7px;
              }

              @include media-breakpoint-down(md) {
                content: '';
                background: url('data:image/svg+xml,<svg width="290" height="16" viewBox="0 0 290 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M145 0L151.243 8.00158L145.002 16.0017L138.759 8.00008L145 0Z" fill="%2332373F"/><path d="M186 8H290" stroke="%2332373F"/><line x1="4.37114e-08" y1="7.5" x2="104" y2="7.50001" stroke="%2332373F"/></svg>') no-repeat;
                top: unset;
                right: calc(50% - 145px);
                bottom: -40px;
                width: 290px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    &--info {
      background: linear-gradient(270deg, $primary-grey 0%, $primary-black 100%);
      padding: 42px 0 49px;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        padding: 27px 0 40px;
      }

      .info {
        &__title {
          color: $primary-white;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.88px;
          text-transform: uppercase;
          margin-bottom: 30px;

          @include media-breakpoint-down(lg) {
            margin-bottom: 25px;
          }
        }

        &__links, &__location {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 20px;
        }

        &__links {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 21px;
          letter-spacing: 0.56px;
          text-transform: uppercase;
          gap: 20px;

          li {
            max-width: 207px;
            margin-left: 37px;

            @include media-breakpoint-down(lg) {
              margin-left: 0;
            }
          }
        }

        &__location {
          color: $primary-white;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.56px;

          @include media-breakpoint-down(lg) {
            margin-bottom: 30px;
          }

          li {
            position: relative;
            max-width: 380px;
            padding-left: 38px;

            &::after {
              display: block;
              position: absolute;
              bottom: -10px;
              content: '';
              background: linear-gradient(270deg, $secondary-gold 0.02%, $third-gold 47.77%, $secondary-gold 99.67%);
              width: 100%;
              max-width: 239px;
              height: 1px;
            }

            &:last-child {
              &::after {
                content: unset;
              }
            }
          }
        }

        &__location-title {
          @include media-breakpoint-down(md) {
            margin-top: 30px;
          }
        }

        &__icon-location, &__icon-phone, &__icon-mail {
          &::before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            left: 0;
          }
        }

        &__icon-location {
          &::before {
            top: 4px;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M17.5 8.33325C17.5 14.1666 10 19.1666 10 19.1666C10 19.1666 2.5 14.1666 2.5 8.33325C2.5 6.34413 3.29018 4.43647 4.6967 3.02995C6.10322 1.62343 8.01088 0.833252 10 0.833252C11.9891 0.833252 13.8968 1.62343 15.3033 3.02995C16.7098 4.43647 17.5 6.34413 17.5 8.33325Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 10.8333C11.3807 10.8333 12.5 9.71396 12.5 8.33325C12.5 6.95254 11.3807 5.83325 10 5.83325C8.61929 5.83325 7.5 6.95254 7.5 8.33325C7.5 9.71396 8.61929 10.8333 10 10.8333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
          }
        }

        &__icon-phone {
          &::before {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M12.5415 4.16659C13.3555 4.32539 14.1035 4.72347 14.6899 5.30986C15.2763 5.89626 15.6744 6.64431 15.8332 7.45825M12.5415 0.833252C14.2326 1.02112 15.8095 1.7784 17.0134 2.98076C18.2173 4.18312 18.9765 5.75909 19.1665 7.44992M18.3332 14.0999V16.5999C18.3341 16.832 18.2866 17.0617 18.1936 17.2744C18.1006 17.487 17.9643 17.6779 17.7933 17.8348C17.6222 17.9917 17.4203 18.1112 17.2005 18.1855C16.9806 18.2599 16.7477 18.2875 16.5165 18.2666C13.9522 17.988 11.489 17.1117 9.32486 15.7083C7.31139 14.4288 5.60431 12.7217 4.32486 10.7083C2.91651 8.53426 2.04007 6.05908 1.76653 3.48325C1.7457 3.25281 1.77309 3.02055 1.84695 2.80127C1.9208 2.58199 2.03951 2.38049 2.1955 2.2096C2.3515 2.03871 2.54137 1.90218 2.75302 1.80869C2.96468 1.7152 3.19348 1.6668 3.42486 1.66659H5.92486C6.32929 1.6626 6.72136 1.80582 7.028 2.06953C7.33464 2.33324 7.53493 2.69946 7.59153 3.09992C7.69705 3.89997 7.89274 4.68552 8.17486 5.44159C8.28698 5.73986 8.31125 6.06401 8.24479 6.37565C8.17832 6.68729 8.02392 6.97334 7.79986 7.19992L6.74153 8.25825C7.92783 10.3445 9.65524 12.072 11.7415 13.2583L12.7999 12.1999C13.0264 11.9759 13.3125 11.8215 13.6241 11.755C13.9358 11.6885 14.2599 11.7128 14.5582 11.8249C15.3143 12.107 16.0998 12.3027 16.8999 12.4083C17.3047 12.4654 17.6744 12.6693 17.9386 12.9812C18.2029 13.2931 18.3433 13.6912 18.3332 14.0999Z" stroke="%23F2F2F2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
          }
        }

        &__icon-mail {
          &::before {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M3.33317 3.33325H16.6665C17.5832 3.33325 18.3332 4.08325 18.3332 4.99992V14.9999C18.3332 15.9166 17.5832 16.6666 16.6665 16.6666H3.33317C2.4165 16.6666 1.6665 15.9166 1.6665 14.9999V4.99992C1.6665 4.08325 2.4165 3.33325 3.33317 3.33325Z" stroke="%23F2F2F2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.3332 5L9.99984 10.8333L1.6665 5" stroke="%23F2F2F2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
          }
        }
      }
    }

    &--bottom {
      background: $primary-grey;

      .footer {

        &-bottom {
          padding: 14px 0 9px;

          &__left {
            ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 20px;

              li {
                position: relative;

                &:after {
                  content: "/";
                  position: absolute;
                  right: -15px;
                }

                &:last-child {
                  &:after {
                    content: none;
                  }
                }
              }
            }
          }

          &__right {
            text-align: right;

            @include media-breakpoint-down(lg) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
