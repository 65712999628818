@mixin dark-bg-1 {
  background: linear-gradient(270deg, $primary-grey 0%, $primary-black 100%);
}

@mixin dark-bg-2 {
  background: linear-gradient(0deg, $primary-grey 0%, $primary-black 96.85%);
}

@mixin dark-bg-3 {
  background: linear-gradient(104deg, $primary-grey -0.41%, $primary-black 95.49%);
}

@mixin gold-bg {
  background: linear-gradient(270deg, $secondary-gold 0.02%, $third-gold 47.77%, $secondary-gold 99.67%);
}
