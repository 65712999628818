a {
  color: $primary-gold;
  text-decoration: none;

  &:hover {
    color: $third-gold;
  }
}

.img-responsive {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.btn {

  &--submit {
    width: 100%;
    border-radius: 5px;
    @include dark-bg-1;
    color: $primary-white;
    border-color: transparent !important;
  }

  &--close {
    padding: 0;
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
    background: transparent;
    border: none;
  }

  &--splide {
    border-radius: 5px;
    border: 1px solid $primary-gold;
    background: rgba(50, 55, 63, 0.50);
    color: $primary-gold;
    font-family: $headings-font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 7px 25px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:hover {
      color: $third-gold;
    }
  }

  &--gallery-tab {
    border-radius: 5px;
    border: 1px solid $primary-gold;
    background: rgba(50, 55, 63, 0.50);
    color: $primary-gold;
    font-family: $headings-font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    padding: 7px 29px;
    min-width: 397px;

    @include media-breakpoint-down(md) {
      min-width: 300px;
      font-size: 16px;
    }

    &:hover {
      color: $primary-gold;
    }
  }
}

// Hero styles
.hero {
  background: linear-gradient(90deg, $primary-grey 0%, $primary-black 100%);

  &__title {
    position: relative;
    font-size: 62px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.86px;
    text-transform: uppercase;
    text-align: center;
    margin: 110px 0 80px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      letter-spacing: 0.84px;
    }

    &::before {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        top: -37px;
        left: calc(50% - 32px);
        background: url('data:image/svg+xml,<svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_115_52)"><path d="M31.9995 0L35.9019 5.00158L32.0005 10.0022L28.0982 5.00064L31.9995 0Z" fill="%23F0D9B0"/><line x1="60" y1="23.5022" x2="4" y2="23.5022" stroke="%23F0D9B0"/></g><defs><filter id="filter0_d_115_52" x="0" y="0" width="64" height="32.0022" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_115_52"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_115_52" result="shape"/></filter></defs></svg>');
        width: 64px;
        height: 32px;
      }
    }

    &::after {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        bottom: -44px;
        left: calc(50% - 32px);
        background: url('data:image/svg+xml,<svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_115_42)"><line x1="4" y1="0.5" x2="60" y2="0.5" stroke="%23F0D9B0"/><path d="M31.9995 14L35.9019 19.0016L32.0005 24.0022L28.0982 19.0006L31.9995 14Z" fill="%23F0D9B0"/></g><defs><filter id="filter0_d_115_42" x="0" y="0" width="64" height="32.0022" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_115_42"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_115_42" result="shape"/></filter></defs></svg>');
        width: 64px;
        height: 32px;
      }
    }

    span {
      position: relative;
      background: linear-gradient(270deg, $secondary-gold 0.02%, $third-gold 47.77%, $secondary-gold 99.67%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span:first-child {
      &:before {
        content: '';
        background: url('data:image/svg+xml,<svg width="97" height="23" viewBox="0 0 97 23" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_113_7)"><line x1="60" y1="8.00061" x2="4" y2="8.00061" stroke="%23F0D9B0"/><path d="M87.0013 14.5013L81.5387 7.49999L87 0.500021L92.4625 7.50131L87.0013 14.5013Z" fill="%23F0D9B0"/></g><defs><filter id="filter0_d_113_7" x="0" y="0.5" width="98.0013" height="22.0013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_7"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_7" result="shape"/></filter></defs></svg>');
        width: 97px;
        height: 23px;
        position: absolute;
        left: -117px;
        top: calc(72px - 48px);

        @include media-breakpoint-down(md) {
          content: unset;
        }
      }

      &:after {
        content: '';
        background: url('data:image/svg+xml,<svg width="97" height="23" viewBox="0 0 97 23" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_113_13)"><path d="M10 0.5L15.4625 7.50129L10.0013 14.5013L4.53878 7.49997L10 0.5Z" fill="%23F0D9B0"/><line x1="37.0012" y1="7.00061" x2="93.0012" y2="7.00061" stroke="%23F0D9B0"/></g><defs><filter id="filter0_d_113_13" x="-1" y="0.5" width="98.0013" height="22.0013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_13"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_13" result="shape"/></filter></defs></svg>');
        width: 97px;
        height: 23px;
        position: absolute;
        right: -117px;
        top: calc(72px - 48px);

        @include media-breakpoint-down(md) {
          content: unset;
        }
      }
    }
  }

  &__subtitle {
    display: block;
    font-family: $base-font-family;
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 0.92px;
    margin-top: 18px;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.64px;
      margin-top: 5px;
    }
  }

  &__about-us, &__home {
    .hero {
      &__row {
        position: relative;
        min-height: 390px;
        color: $primary-white;
      }

      &__title {
        z-index: 2;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0.24px;
        text-transform: unset;
        text-align: left;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.18px;
          margin: 110px 0 40px;
        }

        @include media-breakpoint-down(md) {
          margin: 30px 0 40px;
        }

        &::before, &::after {
          content: unset;
        }
      }

      &__content {
        position: relative;
        z-index: 2;
        font-family: $headings-font-family;
        font-size: 31px;
        font-weight: 400;
        line-height: normal;

        @include media-breakpoint-down(lg) {
          font-size: 22px;
        }

        span {
          display: block;
          font-size: 16px;
          font-weight: 300;

          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
        }
      }

      &__img {
        @include media-breakpoint-down(xl) {
          display: block;
          margin: 0 0 0 auto;
          max-width: 450px;
        }

        @include media-breakpoint-down(lg) {
          max-width: 400px;
        }

        @include media-breakpoint-down(sm) {
          max-width: 335px;
        }
      }

      &__img-container {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

// Home styles
.home-page #top {
  box-shadow: unset;

  .logo {
    @include media-breakpoint-up($hamburger-max-breakpoint) {
      top: 0;
      position: absolute;
    }

    .img {
      @include media-breakpoint-up($hamburger-max-breakpoint) {
        background: url(../img/components/logo-xl-home.png);
        width: 194px;
        height: 70px;
      }
      background-position: center !important;
    }
  }
}

.home {
  &__title {
    font-size: 52px;
    font-weight: 500;
    line-height: 52px;
    text-transform: uppercase;
    margin-bottom: 32px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    span {
      position: relative;
      background: linear-gradient(180deg, $primary-gold 10.31%, rgba(240, 217, 176, 0.00) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &::after {
        content: url('data:image/svg+xml,<svg width="97" height="23" viewBox="0 0 97 23" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_113_13)"><path d="M10 0.5L15.4625 7.50129L10.0013 14.5013L4.53878 7.49997L10 0.5Z" fill="%23F0D9B0"/><line x1="37.0012" y1="7.00061" x2="93.0012" y2="7.00061" stroke="%23F0D9B0"/></g><defs><filter id="filter0_d_113_13" x="-1" y="0.5" width="98.0013" height="22.0013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_13"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_13" result="shape"/></filter></defs></svg>');
        position: absolute;
        right: -117px;

        @include media-breakpoint-down(md) {
          content: unset;
        }
      }
    }
  }

  &__information {
    @include gold-bg;
  }

  &__interventions {
    @include dark-bg-1;
    background-image: url(../img/components/home-intervention-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 45px 0 165px;

    @include media-breakpoint-down($hamburger-max-breakpoint) {
      padding: 25px 0 120px;
    }

    .tabs {
      column-gap: unset;
      row-gap: 15px;
      justify-content: unset;
    }

    .tab {
      &-li {
        flex: 0 0 auto;

        .row {
          row-gap: 15px;
        }

        &__title {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 30px;
          margin-bottom: 10px;
        }

        &__label {
          font-family: $headings-font-family;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.16px;
          text-align: right;
          text-transform: initial;
        }

        a {
          position: relative;
          z-index: 2;
          border-radius: 5px;
          border: 1px solid $primary-gold;
          background: rgba(50, 55, 63, 0.50);
          color: $primary-gold;
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.76px;
          text-transform: uppercase;
          padding: 8px 12px;
          height: 100%;
          display: flex;
          flex-direction: column;
          white-space: inherit;

          @include media-breakpoint-down($hamburger-max-breakpoint) {
            max-width: 100%;
          }

          &.active {
            .tab-li__title {
              font-weight: 600;

              &::before {
                position: relative;
                top: 2px;
                left: 0;
                margin-right: 18px;
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none"><path d="M8.99951 0.000488281L17.5828 11.0016L9.00158 22.0006L0.418311 10.9995L8.99951 0.000488281Z" fill="%23F0D9B0"/></svg>');
              }
            }
          }
        }
      }
    }
  }

  &__judgment {
    @include gold-bg;
    padding: 20px 0 0;

    @include media-breakpoint-down(xl) {
      padding: 0 0;
    }

    &-card {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 25px;
      background: $primary-white;
      border-radius: 5px;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        padding-top: 22px;
        padding-bottom: 8px;
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      max-width: 528px;
    }

    &-splide {
      position: relative;
      top: -60px;

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        top: -40px;
      }

      .splide {
        &__slide {
          position: relative;
          border: none;
          padding-bottom: 10px;
        }

        &__content {
          color: #000;
          font-family: $headings-font-family;
          font-size: 20px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;

          @include media-breakpoint-down($hamburger-max-breakpoint) {
            font-size: 16px;
            padding: 0 9px 0 14px;
          }
        }

        &__footer {
          img {
            position: absolute;
            border-radius: 360px;
            max-width: 160px;
            bottom: 0;
            left: 0;

            @media (min-width: 1200px) and (max-width: 1399.99px) {
              max-width: 120px;
            }

            @include media-breakpoint-down(lg) {
              max-width: 80px;
            }

            @media (max-width: 800px) {
              position: relative;
              left: 14px;
            }

            @include media-breakpoint-down(sm) {
              position: absolute;
            }
          }

          svg {
            margin-bottom: 30px;
            @include media-breakpoint-down($hamburger-max-breakpoint) {
              margin-bottom: 9px;
            }
          }

          p {
            color: #000;
            font-family: $headings-font-family;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;

            @include media-breakpoint-down($hamburger-max-breakpoint) {
              font-size: 14px;
            }
          }

          @include media-breakpoint-down($hamburger-max-breakpoint) {
            padding: 0 9px 0 0;
          }
        }

        &__pagination {
          bottom: -2rem;

          &__page {
            width: 10px;
            height: 10px;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="%23F2F2F2"/></svg>');

            &.is-active {
              transform: scale(1);
              opacity: 1;
              background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M5.00244 0L8.90477 5.00158L5.00338 10.0022L1.10105 5.00064L5.00244 0Z" fill="%2332373F"/></svg>');
            }
          }
        }
      }
    }
  }

  &__gallery {
    @include dark-bg-3;
    padding: 40px 0 30px;

    &-splide {
      .splide {
        &__pagination {
          &__page {
            width: 10px;
            height: 10px;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="%2332373F"/></svg>');

            &.is-active {
              transform: scale(1);
              opacity: 1;
              background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M5.00488 0L8.90722 5.00158L5.00583 10.0022L1.10349 5.00064L5.00488 0Z" fill="%23F0D9B0"/></svg>');
            }
          }
        }
      }
    }

    .splide {
      &__track {
        overflow: unset;
      }

      &__slide {
        &-overlay {
          background: rgb(50 55 63 / 60%);
        }
      }

      &__btn {
        margin-top: 32px;
        text-align: right;

        @include media-breakpoint-down(sm) {
          margin-top: 50px;
        }
      }

      &__pagination {
        @include media-breakpoint-down(sm) {
          bottom: 2.5rem;
        }
      }
    }

    .gallery {

      &__spacer {
        margin: 95px 0 40px;
      }

      &__appearance {
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          list-style: none;
          padding: 0;
          margin: 0;
          column-gap: 62px;
          row-gap: 25px;
        }
      }
    }
  }

  &__plastic {
    background: $primary-grey;
    padding: 40px 0 50px;
    color: $secondary-grey;

    .plastic {
      &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 52px;
        text-transform: uppercase;
        margin-bottom: 25px;

        @include media-breakpoint-down(lg) {
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 16px
        }
      }

      &__content-left, &__content-right, &__content {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0.14px;
      }

      &__spacer {
        margin: 25px 0 40px;
      }

      &__subtitle {
        font-family: $base-font-family;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.18px;
        margin-bottom: 4px;

        @include media-breakpoint-down(lg) {
          margin-bottom: 0;
        }

        span {
          position: relative;
          padding-left: 20px;

          &::before {
            position: absolute;
            top: 5px;
            left: 0;
            content: '';
            background: url('data:image/svg+xml,<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="rombusz_ekru"><path id="Rectangle 110" d="M5.00049 0.957764L8.90282 5.74801L5.00143 10.5374L1.0991 5.74711L5.00049 0.957764Z" fill="white"/></g></svg>') no-repeat;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

// Intervention styles
.intervention {
  margin: 40px 0 60px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.6px;
    margin-bottom: 1rem;
  }
}

.intervention-gallery {
  margin-bottom: 75px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.6px;
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    img {
      cursor: pointer;
    }
  }
}

// Information styles
section.tab-navigation {
  @include gold-bg;
}

section.tab-content {
  padding: 40px 0;

  .information {
    &__title {
      font-family: $base-font-family;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    &__subtitle {
      font-family: $base-font-family;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.48px;
      margin-bottom: 16px;
    }
  }
}

// About us styles
.about-us {
  background: #F9ECD5;
  padding: 40px 0;

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.6px;
    margin-bottom: 12px;
  }

  &__studies, &__exams {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 45px;

    li {
      position: relative;
      padding-left: 25px;

      &::before {
        position: absolute;
        top: 6px;
        left: 4px;
        content: '';
        background: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="rombusz_ekru"><g id="Rectangle 110"><path d="M5.00073 0L8.90306 5.00158L5.00168 10.0022L1.09934 5.00064L5.00073 0Z" fill="%23201C1D"/><path d="M5.00073 0L8.90306 5.00158L5.00168 10.0022L1.09934 5.00064L5.00073 0Z" fill="black" fill-opacity="0.2"/></g></g></svg>') no-repeat;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__slider-title {
    margin-bottom: 45px;
  }

  &__splide {
    .splide {
      &__track {
        overflow: unset;
      }

      &__slide {
        &-overlay {
          background: rgb(249 236 213 / 70%);
        }
      }
    }
  }

  &__introduction-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin: 75px 0 45px;
  }

  &__modal {
    .splide {
      &__slide {
        &-img {
          width: auto;
          margin: auto;
          max-height: 500px;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }
}

// Gallery styles
.gallery-page {
  @include dark-bg-3;

  #top {
    @include media-breakpoint-up($hamburger-max-breakpoint) {
      background: none;
      box-shadow: none;
    }
  }

  .tabs {
    column-gap: unset;
    row-gap: 15px;
    justify-content: unset;
  }

  .tab {
    &-li {
      flex: 0 0 auto;

      .row {
        row-gap: 15px;
      }

      &__title {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      &__label {
        font-family: $headings-font-family;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.16px;
        text-align: right;
      }

      a {
        position: relative;
        z-index: 2;
        border-radius: 5px;
        border: 1px solid $primary-gold;
        background: rgba(50, 55, 63, 0.50);
        color: $primary-gold;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.76px;
        text-transform: uppercase;
        padding: 8px 12px;
        height: 100%;
        display: flex;
        flex-direction: column;
        white-space: inherit;

        @include media-breakpoint-down($hamburger-max-breakpoint) {
          max-width: 100%;
        }

        &.active {
          .tab-li__title {
            font-weight: 600;

            &::before {
              position: relative;
              top: 2px;
              left: 0;
              margin-right: 18px;
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none"><path d="M8.99951 0.000488281L17.5828 11.0016L9.00158 22.0006L0.418311 10.9995L8.99951 0.000488281Z" fill="%23F0D9B0"/></svg>');
            }
          }
        }
      }
    }
  }

  .gallery {
    &__btn {
      margin-bottom: 50px;

      span {
        display: inline-block;
        margin-left: 40px;

        @include media-breakpoint-down($hamburger-max-breakpoint) {
          display: none;
        }
      }

      @include media-breakpoint-down($hamburger-max-breakpoint) {
        margin-bottom: 35px;
      }
    }

    &__contact {
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0.6px;
      color: $secondary-grey;

      a {
        color: $secondary-grey;
      }
    }
  }
}

// Contact styles
.contact {
  background: #F9ECD5;
  padding: 35px 0 45px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  &__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
    gap: 50px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 10px;
    }

    li {
      position: relative;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.6px;
      padding-left: 20px;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        background: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="%23201C1D"/><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="black" fill-opacity="0.2"/></svg>');
        width: 10px;
        height: 10px;
      }
    }
  }

  &__img {
    border-radius: 5px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
      gap: 30px;
      margin-top: 40px;
    }
  }

  &__coordinator {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.6px;

    span {
      display: block;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.48px;
    }
  }

  &__availability {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding-left: 9px;

      svg {
        stroke: $primary-black;
        margin-right: 8px;
      }

      a {
        color: $primary-grey;
      }
    }
  }

  &__information {
    line-height: 22px;
    letter-spacing: 0.48px;
    margin-bottom: 0;
  }

  &__row-address {
    margin-top: 85px;

    p {
      margin-bottom: 0;
    }
  }

  &__address-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
      gap: 30px;
      margin-bottom: 40px;
    }
  }

  &__location {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  &__address {
    position: relative;
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.6px;
    padding-left: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      background: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="%23201C1D"/><path d="M5.00049 0L8.90282 5.00158L5.00143 10.0022L1.0991 5.00064L5.00049 0Z" fill="black" fill-opacity="0.2"/></svg>');
      width: 10px;
      height: 10px;
    }
  }

  &__content {
    line-height: 22px;
    letter-spacing: 0.48px;
  }
}
