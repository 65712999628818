.splide {
  &__arrow {
    background: unset;
    opacity: 1;

    svg {
      height: 40px;
      width: 40px;
    }

    &--prev {
      @include media-breakpoint-up(xl) {
        left: -5rem;
      }

      svg {
        transform: unset;
      }
    }

    &--next {
      @include media-breakpoint-up(xl) {
        right: -5rem;
      }
    }

    &:disabled {
      opacity: 0;
    }
  }

  &__slide {
    border-radius: 8px;
    border: 1px solid $primary-grey;
    overflow: hidden;
    position: relative;

    &:hover {
      cursor: pointer;

      .splide__slide-img {
        &--blur {
          filter: blur(0);
        }
      }
    }

    &-img {
      &--blur {
        filter: blur(3px);
        transition: all 0.5s ease;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.2s;
    }
  }

  &__slide:not(.is-visible) {
    .splide__slide-overlay {
      opacity: 1;
      transition: all 0.2s;
    }
  }
}

.modal {
  &__splide {
    .splide {
      &__slide {
        border: none;
        color: $primary-white;

        &-content {
          font-size: 20px;
          font-style: italic;
          line-height: normal;
          margin-top: 25px;

          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
